import React from 'react'
import PropTypes from 'prop-types'

import MenuItem from './MenuItem'

export default class Menu extends React.Component {
  static propTypes = {
    parent_id: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = { menus: [] };
  }

  loadMenuData = () => {
    if (!this.state.menus.length) {
      $.get({
        url: "/menus/" + this.props.parent_id,
        dataType: 'json',
        success: (data) => {
          this.setState({ menus: data.children })
          $(this.node).slideToggle()
        }
      })
    }
    else {
      $(this.node).slideToggle();
    }
  }

  newMenuItems = (menus) => {
    return menus.map (menu => {
      return (
        <MenuItem key={menu.title} id={menu.id} title={menu.title} imageData={menu.base64_image} imagePath={menu.image_path} url={menu.url} />
      )
    })
  }

  render = () => {
    return (
      <ul ref={(n) => this.node = n} style={{ display: 'none' }} className="list-unstyled menu">
        {this.newMenuItems(this.state.menus)}
      </ul>
    )
  }
}
