$(document).ready( () => {
  $('.layerbtn').click( e => {
    $('.layer[data-layer="' + $(e.target).data('layer') + '"]').fadeToggle()
    $(e.target).hide().siblings().show()
  })

  $('#baseMap').click( e => {
    if (!$('.base[data-base="' + $(e.target).data('base') + '"]').is(":visible")) {
      $('.base[data-base="' + $(e.target).data('base') + '"]').fadeToggle()
        .siblings(":visible").fadeToggle()
      $('.basebtn[data-base="' + $(e.target).data('base') + '"]').show().
        siblings(":visible").hide()
      $('.picinfo[data-base="' + $(e.target).data('base') + '"]').show().
        siblings(":visible").hide()
    }
  })
})
