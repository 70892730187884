import React from 'react'
import PropTypes from 'prop-types'

import Menu from './Menu'

export default class MenuItem extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    imageData: PropTypes.string,
    title: PropTypes.string,
    imageData: PropTypes.string,
    imagePath: PropTypes.string,
    loadMenuData: PropTypes.func,
    url: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e) => {
    e.stopPropagation()
    if (!this.props.url) {
      e.preventDefault()
      this.childMenu.loadMenuData(this.props)
    }
  }

  menuLinkedImage = () => {
    return (
      <a href={this.props.url}>{this.menuImage()}</a>
    )
  }

  menuImage = () => {
    return (
      <img src={this.props.imageData} alt={this.props.title} />
    )
  }

  menuItem = () => {
    if (this.props.url) return this.menuLinkedImage(); else return this.menuImage();
  }

  childMenuContainer = () => {
    return (
      <Menu ref={instance => { this.childMenu = instance }} parent_id={this.props.id} />
    )
  }

  render = () => {
    return (
      <li className='foo' onClick={this.handleClick}>
        {this.menuItem()}
        {this.childMenuContainer()}
      </li>
    )
  }
}
